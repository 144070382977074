import { IconCircleCheck } from '@troon/icons/circle-check';
import { useAuthStore } from './flow';
import type { FlowStep } from './flow';
import type { JSXElement } from 'solid-js';

export const authHeaders: Record<FlowStep, () => JSXElement> = {
	'/auth': () => (
		<p>
			Enter the email associated with your Troon Rewards Account to log in. If you don’t have one, you can create a new
			account for free.
		</p>
	),
	'/auth/login': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We found an existing Troon Rewards account for <b>{store.data.email}</b>. Enter your password to login and
					continue.
				</p>
			</>
		);
	},
	'/auth/join': () => {
		return (
			<>
				<FoundUser />
				<p>Enter the following information to set up your Troon Account.</p>
			</>
		);
	},
	'/auth/reset': () => (
		<>
			<FoundUser />
			<p>Check your email for a confirmation code.</p>
		</>
	),
	'/auth/reset/password': () => (
		<>
			<FoundUser />
			<p>Create a password with at least 8 letters and numbers.</p>
		</>
	),
	'/auth/magic-link': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We sent a login code to <b>{store.data.email}</b>. Copy the code and enter it below to continue logging in.
				</p>
			</>
		);
	},
};

function FoundUser() {
	const [store] = useAuthStore();
	return (
		<p class="flex flex-row items-center gap-2 rounded border border-neutral-300 bg-neutral-100 px-4 py-3 text-neutral-800">
			<IconCircleCheck class="size-6 text-green-500" />
			{store.data.email}
		</p>
	);
}
